import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "antd";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

// redux
import { setIsCookiesAccepted } from "../../redux/auth/authActions";

// styles
import "../../assets/styles/shared/cookies-notification.scss";

const cookie = new Cookies();

const CookiesNotification = ({ setIsCookie }) => {
  // Methods

  const handleAcceptCookie = (e) => {
    const currentDate = new Date();
    const expirationDate = new Date(
      currentDate.setFullYear(currentDate.getFullYear() + 5)
    );

    setIsCookie({ cookieValue: e, expirationDate });
  };

  if (cookie.get("cookiePolicyAccepted")) {
    return null;
  }

  return (
    <div className="cookies-notification">
      <div className="cookies-notification__label">
        This website uses cookies to give you a better experience. Please see
        our
        <Link to="/privacy-policy"> Cookie Policy </Link>
        for details
      </div>
      <Button
        className="cookies-notification--accept-btn"
        onClick={() => handleAcceptCookie("true")}
      >
        Accept Cookies
      </Button>
    </div>
  );
};

CookiesNotification.propTypes = {
  setIsCookie: PropTypes.func.isRequired,
};

export default connect(null, {
  setIsCookie: setIsCookiesAccepted,
})(CookiesNotification);
